//import clsx from "clsx";
import { useContext } from "react";
import Media from "react-media";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import "./Footer.css";
import { ApplicationContext } from "../../context/GlobalState";

//const useWatchBasedMediaQuery = () => useMediaQuery({ maxWidth: "318px" });

const Footer = ({ className }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const { isAuthenticated } = state;

  return (
    <>
      <footer className="footer-menu ftr">
        <Row className="copyright">
          <a href="https://ecomindustries.io/advertising" className="ftr-link">
            Powered by{" "}
            <Media
              query="(min-width: 319px) and (max-width: 375px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon-xs"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 376px) and (max-width: 480px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon-xs"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 481px) and (max-width: 600px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon-md"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 601px) and (max-width: 767px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon-md"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 768px) and (max-width: 991px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon-md"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 992px) and (max-width: 1180px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 1181px) and (max-width: 1350px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon"
                  responsive="true"
                />
              )}
            />
            <Media
              query="(min-width: 1351px)"
              render={() => (
                <Image
                  src={
                    isAuthenticated
                      ? "../icons/Lightning.png"
                      : "./icons/Lightning.png"
                  }
                  className="ftr-icon"
                  responsive="true"
                />
              )}
            />{" "}
            ECOM Industries
          </a>
        </Row>
      </footer>
    </>
  );
};

export default Footer;
