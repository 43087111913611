//import marketplaceBgLeft from 'assets/images/partials/marketplace-bg-left.svg';
//import marketplaceBgRight from 'assets/images/partials/marketplace-bg-right.svg';
//import { useApiCall } from 'hooks';
//import { getItems } from 'services/apiService';
import { useEffect } from "react";
import Main from "../../layouts/Main";
import "./Home.css";

const Home = () => {
  //const [fetchItem, , items] = useApiCall(getItems);
  useEffect(() => {
    //
  }, []);

  return (
    <>
      <Main>
        <article className="Home">
          <div className="lander">
            <h1>Real Estate Sales Lead Automation</h1>
            <p>Automated Leads to your sales agent's inbox.</p>
          </div>
        </article>
      </Main>
    </>
  );
};

export default Home;
