import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ApplicationContext } from "../../context/GlobalState";

const AuthenticatedRoute = ({ children }) => {
  //
  const [state, dispatch] = useContext(ApplicationContext);
  const { pathname, search } = useLocation();
  const { isAuthenticated } = state;

  console.log("AUTH pathname: ", pathname);
  console.log("AUTH search: ", search);
  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }
  console.log("AUTH CHILDREN: ", children);
  return children;
};

export default AuthenticatedRoute;

/* EtherStream <ProtectedRoute />

import { useContext, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ApplicationContext } from 'context/GlobalState';
import { connectWalletModalOpen } from 'context/actions';
import queryString from 'query-string';

export const ProtectedRoute = ({ path, children, ...otherProps }) => {
  const [state, dispatch] = useContext(ExpenseContext);
  const { user } = state;

  const location = useLocation();

  useEffect(() => {
    let params = queryString.parse(location.search);
    if (params?.affiliate) {
      sessionStorage.setItem('affiliate', params?.affiliate);
    }
  }, []);

  if (user) {
    return (
      <Route path={path} {...otherProps}>
        {children}
      </Route>
    );
  } else {
    dispatch(connectWalletModalOpen());
    return <Redirect path="/" />;
  }
};

*/
