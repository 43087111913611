import React, { useState, useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { LoaderButton } from "../../../components/LoaderButton";
import { onError } from "../../../libs/errorLib";
import { useFormFields } from "../../../hooks";
import Main from "../../../layouts/Main";
import "./ConfirmationCode.css";
import { ApplicationContext } from "../../../context/GlobalState";
import {
	userHasAuthenticated,
	setUserEmail,
	updateUser,
} from "../../../context/actions";

const ConfirmationCode = () => {
	const [state, dispatch] = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);
	//const [newUser, setNewUser] = useState(null);

	const [fields, handleFieldChange] = useFormFields({
		confirmPassword: "",
		confirmationCode: "",
	});

	const { barRef } = state;
	const nav = useNavigate();

	console.log("REGISTER_STATE: ", state);

	useEffect(() => {
		if (isLoading) {
			barRef?.current?.continuousStart();
		} else {
			barRef?.current?.complete();
		}
	}, [isLoading]);

	const validateConfirmationForm = () => {
		//
		return fields.confirmationCode.length > 0;
	};

	const handleConfirmationSubmit = async (e) => {
		//
		e.preventDefault();
		setIsLoading(true);

		try {
			//
			await Auth.confirmSignUp(fields.email, fields.confirmationCode);
			await Auth.signIn(fields.email, fields.password);
			dispatch(userHasAuthenticated(true));
			setIsLoading(false);
			nav("/");
		} catch (e) {
			//
			onError(e);
			setIsLoading(false);
		}
	};

	return (
		<>
			<Main>
				<article className="ConfirmationCode">
					<Form
						onSubmit={handleConfirmationSubmit}
						className="registration-form"
					>
						<Form.Group controlId="confirmationCode" size="lg">
							<Form.Label>Confirmation Code</Form.Label>
							<Form.Control
								autoFocus
								type="tel"
								onChange={handleFieldChange}
								value={fields.confirmationCode}
							/>
							<Form.Text muted>
								Please check your email for the code or
								verification link.
							</Form.Text>
						</Form.Group>

						<section className="registration-submit-btn">
							<LoaderButton
								block="true"
								size="lg"
								type="submit"
								variant="success"
								isLoading={isLoading}
								disabled={!validateConfirmationForm()}
							>
								Verify
							</LoaderButton>
						</section>
					</Form>
				</article>
			</Main>
		</>
	);
};

export default ConfirmationCode;
