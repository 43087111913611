import { useRef, useContext, useEffect, useState } from "react";
import { ApplicationContext } from "./context/GlobalState";
import {
  userHasAuthenticated,
  setLoadingBar,
  setUserEmail,
  updateUser,
  setUserPrimaryAccountId,
} from "./context/actions";
import { LoadingBar } from "./components/LoadingBar";
import { Auth } from "aws-amplify";
import Router from "./routes/Routes";
//import logo from "./logo.svg";
//import Home from "./containers/Home";
import "./App.css";

const App = () => {
  // on compponent did mount/change/etc
  const loadingBarRef = useRef(null);
  const [state, dispatch] = useContext(ApplicationContext);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [loading, setLoading] = useState(false);
  const { barRef, isAuthenticated } = state;

  const onLoad = async () => {
    setLoading(true);
    try {
      let sesh = await Auth.currentSession();
      console.log("App Session: ", sesh);

      console.log(
        "App Session - superID: ",
        sesh.idToken.payload["custom:superId"]
      );
      console.log(
        "App Session - userName: ",
        sesh.idToken.payload["custom:userName"]
      );
      console.log(
        "App Session - userRole: ",
        sesh.idToken.payload["custom:userRole"]
      );
      console.log("App Session - email: ", sesh.idToken.payload.email);
      console.log(
        "App Session - verified email: ",
        sesh.idToken.payload.email_verified
      );

      dispatch(userHasAuthenticated(true));
      dispatch(setUserEmail(sesh.idToken.payload.email));
      dispatch(setUserPrimaryAccountId(sesh.idToken.payload["custom:superId"]));
      dispatch(
        updateUser({
          userRole: sesh.idToken.payload["custom:userRole"],
          superId: sesh.idToken.payload["custom:superId"],
          username: sesh.idToken.payload["custom:userName"],
          verified: sesh.idToken.payload.email_verified,
        })
      );

      console.log("onLoad fxn: ", state);
    } catch (e) {
      //
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!barRef) dispatch(setLoadingBar(loadingBarRef));
  }, [barRef]);

  useEffect(() => {
    if (loading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [loading]);

  useEffect(() => {
    console.log("./App Auth? ", isAuthenticated);
    try {
      onLoad();
    } catch (e) {
      //
      console.log("ERROR: ", e);
    }
  }, []);

  return (
    !isAuthenticating && (
      <div className="App">
        <LoadingBar ref={loadingBarRef} />
        <Router />
      </div>
    )
  );
};

export { App };
