import React from "react";
import { Routes, Route } from "react-router-dom";

//import AppliedRoute from "../AppliedRoute";
import AuthenticatedRoute from "../AuthenticatedRoute";
import UnauthenticatedRoute from "../UnauthenticatedRoute";

import Home from "../../containers/Home";
import Login from "../../containers/Login";
import Register from "../../containers/Register";
import ResetPassword from "../../containers/ResetPassword";
import ConfirmationCode from "../../containers/Register/ConfirmationCode-NotUsed";

import NewSalesLead from "../../containers/NewSalesLead";
import SalesLeads from "../../containers/SalesLeads";

import Page404 from "../../components/Page404";
//import TermsAndConditions from "./components/TermsAndConditions";
//import PrivacyPolicy from "./components/PrivacyPolicy";

const Router = ({ childProps }) => (
  <Routes>
    {/* This is our home page route for the main landing page to the app dashboard */}

    <Route path="/" exact element={<Home />} props={childProps} />
    <Route
      path="/login"
      element={
        <UnauthenticatedRoute>
          <Login />
        </UnauthenticatedRoute>
      }
      props={childProps}
    />

    <Route
      path="/register"
      element={
        <UnauthenticatedRoute>
          <Register />
        </UnauthenticatedRoute>
      }
      props={childProps}
    />

    {/* FIXME: This MUST BE <UnauthenticatedRoute /> Route*/}
    <Route
      path="/reset-password"
      element={<ResetPassword />}
      props={childProps}
    />

    <Route
      path="/insertion-order/new"
      element={
        <AuthenticatedRoute>
          <NewSalesLead />
        </AuthenticatedRoute>
      }
    />

    <Route
      path="/sales-leads"
      element={
        <AuthenticatedRoute>
          <SalesLeads />
        </AuthenticatedRoute>
      }
    />

    <Route
      path="/confirm-registration/:email/:confirmationCode"
      element={<ConfirmationCode />}
      props={childProps}
    />

    {/* This is the Login Route
    <Route path="/" exact element={<Home />} props={childProps} />*/}

    <Route path="*" element={<Page404 />} />
  </Routes>
);

export default Router;
