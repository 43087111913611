import { useContext } from "react";
import Media from "react-media";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Image from "react-bootstrap/Image";
import { LinkContainer } from "react-router-bootstrap";
import { Auth, API } from "aws-amplify";
import { ApplicationContext } from "../../context/GlobalState";
import { userHasAuthenticated, updateUser } from "../../context/actions";
import { useNavigate } from "react-router-dom";
import "./NavigationBar.css";

const NavigationBar = () => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const { barRef, isAuthenticated } = state;

  console.log("NAV auth?: ", isAuthenticated);

  console.log("state?: ", state);

  const nav = useNavigate();

  const handleLogout = async () => {
    setLoading(true);
    // NOTE: handle logout from AWS
    await Auth.signOut();
    dispatch(updateUser(null));
    dispatch(userHasAuthenticated(false));
    setLoading(false);
    nav("/login");
  };

  useEffect(() => {
    if (loading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [loading]);

  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar
          key={expand}
          bg="dark"
          variant="dark"
          expand={expand}
          className="mb-3"
        >
          <Container fluid>
            <LinkContainer to="/">
              <Navbar.Brand>
                <Image
                  src="" //isAuthenticated ? "../icons/xxx.svg" : "./icons/xxx.svg"
                  className="nav-logo"
                  responsive="true"
                />
                AutoMater Leads
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header
                className="mobile-menu-header"
                closeVariant="white"
                closeButton
              >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Business Control Panel
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Media
                query="(max-width: 1399px)"
                render={() => (
                  <Offcanvas.Body className="mobile-menu">
                    {/* NOTE: Set an activeKey so the route in the address bar matches and highlights the link selected */}
                    <Nav
                      className="justify-content-end flex-grow-1 pe-3"
                      activeKey={window.location.pathname}
                    >
                      {isAuthenticated ? (
                        <div>
                          <h6>
                            <em>
                              <strong>Welcome </strong>
                              {state.user.username}
                            </em>
                          </h6>
                          <p style={{ fontSize: ".72rem" }}>
                            <strong>Org ID: </strong>
                            {state.user.superId}
                          </p>
                          <LinkContainer to="/logout" onClick={handleLogout}>
                            <Nav.Link href="/logout">Logout</Nav.Link>
                          </LinkContainer>
                        </div>
                      ) : (
                        <>
                          <LinkContainer to="/register">
                            <Nav.Link href="/register">Register</Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/login">
                            <Nav.Link href="/login">Login</Nav.Link>
                          </LinkContainer>
                        </>
                      )}

                      {isAuthenticated ? (
                        <NavDropdown
                          title="Services"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                          className="dropdown-menu-adNucleus"
                        >
                          <LinkContainer to="/sales-lead/new">
                            <NavDropdown.Item href="/sales-lead/new">
                              New Sales Lead
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/sales-leads">
                            <NavDropdown.Item href="/sales-leads">
                              Sales Leads
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/payments">
                            <NavDropdown.Item href="/payments">
                              Payments
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/campaigns">
                            <NavDropdown.Item href="/campaigns">
                              Campaigns
                            </NavDropdown.Item>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/data-dashboard">
                            <NavDropdown.Item href="/data-dashboard">
                              Data Dashboard
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : (
                        <>
                          <NavDropdown
                            title="Services"
                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                            className="dropdown-menu-adNucleus"
                          >
                            <LinkContainer to="/features">
                              <NavDropdown.Item href="/features">
                                Features
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/case-studies">
                              <NavDropdown.Item href="/case-studies">
                                Case Studies
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/pricing">
                              <NavDropdown.Item href="/pricing">
                                Pricing
                              </NavDropdown.Item>
                            </LinkContainer>

                            <NavDropdown.Divider className="nav-divider" />
                            <LinkContainer to="/terms-and-conditions">
                              <NavDropdown.Item href="/terms-and-conditions">
                                Terms & Conditions
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/privacy-policy">
                              <NavDropdown.Item href="/privacy-policy">
                                Privacy Policy
                              </NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                        </>
                      )}
                    </Nav>

                    <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2 nucleus-form-fields"
                        aria-label="Search"
                      />
                      <Button variant="outline-success">Search</Button>
                    </Form>
                  </Offcanvas.Body>
                )}
              />

              <Media
                query="(min-width: 1400px)"
                render={() => (
                  <Offcanvas.Body className="">
                    {/* NOTE: Set an activeKey so the route in the address bar matches and highlights the link selected */}
                    <Nav
                      className="justify-content-end flex-grow-1 pe-3"
                      activeKey={window.location.pathname}
                    >
                      {isAuthenticated ? (
                        <LinkContainer to="/logout" onClick={handleLogout}>
                          <Nav.Link href="/logout">Logout</Nav.Link>
                        </LinkContainer>
                      ) : (
                        <>
                          <LinkContainer to="/register">
                            <Nav.Link href="/register">Register</Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/login">
                            <Nav.Link href="/login">Login</Nav.Link>
                          </LinkContainer>
                        </>
                      )}

                      {isAuthenticated ? (
                        <NavDropdown
                          title="Services"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                          className="dropdown-menu-adNucleus"
                        >
                          <LinkContainer to="/sales-lead/new">
                            <NavDropdown.Item href="/sales-lead/new">
                              New Sales Lead
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/sales-leads">
                            <NavDropdown.Item href="/sales-leads">
                              Sales Leads
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/payments">
                            <NavDropdown.Item href="/payments">
                              Payments
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/campaigns">
                            <NavDropdown.Item href="/campaigns">
                              Campaigns
                            </NavDropdown.Item>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/data-dashboard">
                            <NavDropdown.Item href="/data-dashboard">
                              Data Dashboard
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : (
                        <>
                          <NavDropdown
                            title="Services"
                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                            className="dropdown-menu-adNucleus"
                          >
                            <LinkContainer to="/features">
                              <NavDropdown.Item href="/features">
                                Features
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/case-studies">
                              <NavDropdown.Item href="/case-studies">
                                Case Studies
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/pricing">
                              <NavDropdown.Item href="/pricing">
                                Pricing
                              </NavDropdown.Item>
                            </LinkContainer>

                            <NavDropdown.Divider className="nav-divider" />
                            <LinkContainer to="/terms-and-conditions">
                              <NavDropdown.Item href="/terms-and-conditions">
                                Terms & Conditions
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/privacy-policy">
                              <NavDropdown.Item href="/privacy-policy">
                                Privacy Policy
                              </NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                        </>
                      )}
                    </Nav>

                    <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2 nucleus-form-fields"
                        aria-label="Search"
                      />
                      <Button variant="outline-success">Search</Button>
                    </Form>
                  </Offcanvas.Body>
                )}
              />
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default NavigationBar;
