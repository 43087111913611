// Configure dev and PROD for CICD
// FIXME: Implement using process.env for prod!

const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "automater-uploads-api-de-automaterattachmentsbuck-1hjzak1ixi0d2",
  },

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dhzjbr83kg.execute-api.us-east-1.amazonaws.com/dev",
  },

  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_mJpOI4LR8",
    APP_CLIENT_ID: "7k9r3lqd06sieeudpob4jj6gtu",
    IDENTITY_POOL_ID: "us-east-1:b7cc1412-6e08-41d8-8b3b-5079b4c43f26",
  },

  Places_API_KEY: "AIzaSyCf9gHej_w8002o5tBNJ9jaKO90kDRLM8c",
  APPROVAL_CODE: "dlo_testing_only",
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "automater-uploads-api-de-automaterattachmentsbuck-1hjzak1ixi0d2",
  },

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://dhzjbr83kg.execute-api.us-east-1.amazonaws.com/dev",
  },

  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_mJpOI4LR8",
    APP_CLIENT_ID: "7k9r3lqd06sieeudpob4jj6gtu",
    IDENTITY_POOL_ID: "us-east-1:b7cc1412-6e08-41d8-8b3b-5079b4c43f26",
  },

  Places_API_KEY: "AIzaSyCf9gHej_w8002o5tBNJ9jaKO90kDRLM8c",
  APPROVAL_CODE: "dlo_testing_only",
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

// QAT Credentials as deployed in serverless for CRM case-records
export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  ...config,
};
