import React, { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { LoaderButton } from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useFormFields } from "../../hooks";
import Main from "../../layouts/Main";
import { ApplicationContext } from "../../context/GlobalState";
import { BsPencilSquare } from "react-icons/bs";
import {
  userHasAuthenticated,
  setUserEmail,
  updateUser,
} from "../../context/actions";

import "./SalesLeads.css";

const SalesLeads = () => {
  const [state, dispatch] = useContext(ApplicationContext);

  const nav = useNavigate();
  const [campaignId, setCampaignId] = useState("Select CampaignID");
  const [leads, setLeads] = useState("");
  const [nextPaginationItem, setNextPaginationItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user, barRef } = state;

  console.log("SalesLeads state: ", state);
  console.log("SalesLeads nextPaginationItem: ", nextPaginationItem);

  useEffect(() => {
    if (isLoading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [isLoading]);

  useEffect(() => {
    if (campaignId !== "Select CampaignID" || nextPaginationItem.length === 0) {
      try {
        // call function to get lead data
        const fetchData = async () => {
          const res = await getSalesLeads();

          return res;
        };

        const result = fetchData();

        result.then((r) => {
          console.log("checking: ", r);
          setLeads(r.items);
          setNextPaginationItem(r.LastEvaluatedKey);
        });
      } catch (e) {
        // diusplay error here
        console.log("lead error message: ", e);
      }
    } else {
      // test
      console.log("LEADS: NONE");
    }
  }, [campaignId]);

  const clickNext = async (e) => {
    e.preventDefault();
    //
    console.log("NEXT: ", nextPaginationItem);
    const res = await getSalesLeads();
    console.log("checking: ", res);

    setLeads(res.items);

    setNextPaginationItem((nextPaginationItem) => [
      { ...nextPaginationItem },
      res.LastEvaluatedKey,
    ]);

    console.log("clickNext End: ", nextPaginationItem);
  };

  const loadSalesLeads = (cid) => {
    if (cid !== campaignId) {
      console.log("cid:", cid);
      setNextPaginationItem((nextPaginationItem) => []);
      setLeads("");
      setCampaignId(cid);
    }
    // TODO: Update when campaign id is the same
  };

  const getSalesLeads = async () => {
    console.log("getSalesLeads() TEST ", campaignId);

    // call lead-records to obtain all leads in db
    let prom = await API.post("automater-api", `/lead-records/${campaignId}`, {
      body: {
        lastItem:
          nextPaginationItem?.length === 0 ||
          typeof nextPaginationItem === "undefined"
            ? null
            : nextPaginationItem,
      },
    });

    return prom;
  };

  const renderSalesLeadsList = (salesLeads) => {
    // setup leads to display here
    return (
      <>
        {salesLeads.map(
          ({
            leadID,
            campaignId,
            email,
            propertyCity,
            propertyState,
            lastName,
            firstName,
            phoneNumber,
          }) => (
            <LinkContainer
              className="create-new-leads"
              key={leadID + phoneNumber}
              to={`/lead-records/${leadID}`}
            >
              <ListGroup.Item action>
                <span className="font-weight-bold create-new">
                  <strong style={{ color: "#e60061" }}>
                    {lastName + ", " + firstName}
                  </strong>
                  {" - " + propertyState}
                </span>
                <br />

                <span className="font-weight-bold create-new">
                  {"Phone: " + phoneNumber}
                </span>
                <br />

                <span className="text-muted email-list">{email}</span>
              </ListGroup.Item>
            </LinkContainer>
          )
        )}
      </>
    );
  };

  const renderSalesLeads = () => {
    console.log("CLASSNAME: ", typeof nextPaginationItem);
    let nextBtn = "click-next";

    if (typeof nextPaginationItem === "undefined") {
      //
      console.log("t1: ", typeof nextPaginationItem);
      nextBtn = "click-next-none";
    }

    if (
      typeof nextPaginationItem !== "undefined" &&
      typeof nextPaginationItem[0] === "object" &&
      typeof nextPaginationItem[nextPaginationItem.length - 1] === "undefined"
    ) {
      //
      console.log(
        "t2: ",
        typeof nextPaginationItem[nextPaginationItem.length - 1]
      );
      nextBtn = "click-next-none";
    }

    if (
      typeof nextPaginationItem !== "undefined" &&
      typeof nextPaginationItem[0] === "object" &&
      typeof nextPaginationItem[nextPaginationItem.length - 1] === "object"
    ) {
      //
      console.log(
        "t3: ",
        typeof nextPaginationItem[nextPaginationItem.length - 1]
      );
      nextBtn = "click-next";
    }

    // render list group here
    return (
      <section className="leads">
        <ListGroup className="leads-group">
          <Row style={{ marginBottom: ".6rem" }}>
            <Col className="click-previous" style={{ cursor: "pointer" }}>
              {"<< "} Previous
            </Col>
            <Col
              className={nextBtn}
              style={{ textAlign: "end", cursor: "pointer" }}
              onClick={clickNext}
            >
              Next{" >>"}
            </Col>
          </Row>
          <LinkContainer
            className="create-new-leads"
            key="1"
            to="/lead-records/new"
          >
            <ListGroup.Item action className="py-3 text-nowrap text-truncate">
              <BsPencilSquare
                size={17}
                className="create-new"
                style={{ marginRight: "0.6rem" }}
              />
              <span className="ml-2 font-weight-bold create-new">
                Create a new lead
              </span>
            </ListGroup.Item>
          </LinkContainer>
          {!isLoading && leads.length > 0 && renderSalesLeadsList(leads)}
        </ListGroup>
      </section>
    );
  };

  return (
    <>
      <Main>
        <article className="SalesLeads">
          <div className="lander">
            <h1>Sales Leads</h1>
            <p>Automated leads delivered in realtime-from Z-buyer.</p>

            <Row>
              <Col>
                <Dropdown className="dropdown-sales-list">
                  <Dropdown.Toggle
                    id="dropdown-button-dark-1"
                    className="campaign-id-btn"
                    variant="secondary"
                  >
                    {campaignId}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <Dropdown.Item
                      className="dropdown-item-leads-list"
                      onClick={() => loadSalesLeads("8e88894ce1")}
                    >
                      8e88894ce1
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item-leads-list"
                      onClick={() => loadSalesLeads("7fd8ceef1d")}
                    >
                      7fd8ceef1d
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item-leads-list"
                      onClick={() => loadSalesLeads("319c26022d")}
                    >
                      319c26022d
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item-leads-list"
                      onClick={() => loadSalesLeads("69bda915ed")}
                    >
                      69bda915ed
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="dropdown-item-leads-list">
                      New Campaign
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col>
                <span className="text-muted email-list">
                  {"Lead Count: " + leads.length}
                </span>
              </Col>
            </Row>

            {state.isAuthenticated ? renderSalesLeads() : nav("/login")}
          </div>
        </article>
      </Main>
    </>
  );
};

export default SalesLeads;
