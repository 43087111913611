export const userHasAuthenticated = (bool) => ({
  type: "USER_HAS_AUTHENTICATED",
  payload: bool,
});

export const setUserEmail = (email) => ({
  type: "SET_USER_EMAIL",
  payload: email,
});

export const setUserBillingAddress = (billingAddress) => ({
  type: "SET_USER_BILLING_ADDRESS",
  payload: billingAddress,
});

export const setUserPrimaryAccountId = (primaryAccountId) => ({
  type: "SET_USER_PRIMARY_ACCOUNT_ID",
  payload: primaryAccountId,
});

// v0.1 below
export const setLoadingBar = (ref) => ({
  type: "SET_LOADING_BAR",
  payload: ref,
});

export const setTheme = (theme) => ({
  type: "SET_THEME",
  payload: theme,
});

export const setActiveTab = (tab) => ({
  type: "SET_ACTIVE_TAB",
  payload: tab,
});

export const setFilterTab = (tab) => ({
  type: "SET_FILTER_TAB",
  payload: tab,
});

export const connectWallet = (wallet) => ({
  type: "CONNECT_WALLET",
  payload: wallet,
});

export const connectWalletModalOpen = () => ({
  type: "CONNECT_WALLET_MODAL_OPEN",
});

export const connectWalletModalClose = () => ({
  type: "CONNECT_WALLET_MODAL_CLOSE",
});

export const updateUser = (data) => ({
  type: "UPDATE_USER",
  payload: data,
});

export const logout = () => ({
  type: "LOG_OUT",
  payload: null,
});
