import React, { useState, useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { LoaderButton } from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useFormFields } from "../../hooks";
import Main from "../../layouts/Main";
import "./Login.css";
import { ApplicationContext } from "../../context/GlobalState";
import {
	userHasAuthenticated,
	setUserEmail,
	updateUser,
	setUserPrimaryAccountId,
} from "../../context/actions";

const Login = () => {
	const [state, dispatch] = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);

	const [fields, handleFieldChange] = useFormFields({
		email: "",
		password: "",
	});

	const { barRef } = state;

	console.log("LOGIN_STATE: ", state);

	const nav = useNavigate();

	const validateForm = () => {
		return fields.email.length > 0 && fields.password.length > 0;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			// NOTE: handle login from AWS
			const user = await Auth.signIn(
				fields.email.toLowerCase(),
				fields.password
			);

			console.log("USER: ", user);
			console.log("USER EMAIL: ", fields.email);

			dispatch(userHasAuthenticated(true));

			dispatch(
				setUserPrimaryAccountId(user.attributes["custom:superId"])
			);

			dispatch(
				updateUser({
					userRole: user.attributes["custom:userRole"],
					superId: user.attributes["custom:superId"],
					username: user.attributes["custom:userName"],
					verified: user.attributes.email_verified,
				})
			); // NOTE: make sure to update space with user sttributes alone

			dispatch(setUserEmail(fields.email.toLowerCase()));
			setIsLoading(false);
			nav("/");
		} catch (e) {
			//
			console.log("LoginError: ", e.message);

			try {
				if (e.message === "User is not confirmed.") {
					//
					console.log("LoginError-part2");
					let user = {
						username: fields.email.toLowerCase(),
						password: fields.password,
						attributes: {
							// FIXME: This is wrong needs to take queryParams into consideration
							"custom:userRole": "User_Admin", //this.state.userRole, // custom attribute, not standard
							"custom:superId": "LCM_GROUP_33010", //this.state.primaryAccountId.toLowerCase(), // custom attribute, not standard
							"custom:userName": "nativestack", //this.state.userName.toLowerCase(), // custom attribute, not standard
						},
					};

					onError(
						e.message +
							" Please check your email for a new confirmation code!"
					);

					dispatch(updateUser(user));
					dispatch(setUserEmail(fields.email.toLowerCase()));

					const authUser = await Auth.resendSignUp(
						fields.email.toLowerCase()
					);

					// FIXME: get whats coming back in authUser to get the right pid into the updateUser dispatch!!!!

					setIsLoading(false);
					nav("/register");
				} else {
					onError(e.message);
					setIsLoading(false);
					nav("/login");
				}
			} catch (e) {
				onError(e.message);
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		if (isLoading) {
			barRef?.current?.continuousStart();
		} else {
			barRef?.current?.complete();
		}
	}, [isLoading]);

	return (
		<>
			<Main>
				<article className="Login">
					<Form onSubmit={handleSubmit} className="login-form">
						<Form.Group size="lg" controlId="email" id="fg1">
							<Form.Label>Email</Form.Label>
							<Form.Control
								autoFocus
								type="email"
								value={fields.email}
								onChange={handleFieldChange}
								className="nucleus-form-fields"
							/>
						</Form.Group>

						<Form.Group size="lg" controlId="password" id="fg2">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								value={fields.password}
								onChange={handleFieldChange}
								className="nucleus-form-fields"
							/>
						</Form.Group>
						<Link to="/reset-password">Forgot Password?</Link>

						<section className="login-form-submit-btn">
							<LoaderButton
								block="true"
								size="lg"
								type="submit"
								isLoading={isLoading}
								disabled={!validateForm()}
							>
								Login
							</LoaderButton>
						</section>
					</Form>
				</article>
			</Main>
		</>
	);
};

export default Login;
