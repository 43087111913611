import { useReducer, createContext } from "react";
//import { Network } from "services/web3Service";

export const ApplicationContext = createContext();

const initialState = {
  theme: "dark",
  //activeTab: "art",
  //filterTab: "trending",
  user: null,
  email: "",
  primaryAccountId: "",
  billingAddress: {
    streetAddress: "",
    streetAddressLine2: "",
    city: "",
    stateOf: "",
    zipCode: "",
    country: "",
  },
  accountStatus: null,
  isAuthenticated: false,
  barRef: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "USER_HAS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case "SET_USER_EMAIL":
      return {
        ...state,
        email: action.payload,
      };

    case "SET_USER_BILLING_ADDRESS":
      return {
        ...state,
        billingAddress: action.payload,
      };

    case "SET_USER_PRIMARY_ACCOUNT_ID":
      return {
        ...state,
        primaryAccountId: action.payload,
      };

    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };

    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };

    case "SET_FILTER_TAB":
      return {
        ...state,
        filterTab: action.payload,
      };

    case "CONNECT_WALLET":
      return {
        ...state,
        walletInfo: action.payload,
      };

    case "CONNECT_WALLET_MODAL_OPEN":
      return {
        ...state,
        connectWalletModalOpen: true,
      };

    case "CONNECT_WALLET_MODAL_CLOSE":
      return {
        ...state,
        connectWalletModalOpen: false,
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };

    case "SET_LOADING_BAR":
      return {
        ...state,
        barRef: action.payload,
      };

    // NOTE: Handled with userHasAuthenticated
    case "LOG_OUT":
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("signature");
      //Network._resetSingletons();
      return {
        ...state,
        user: null,
        //walletInfo: null,
      };

    case "CHANGE_AFFILIATE":
      return {
        ...state,
        affiliate: action.payload,
      };

    default:
      return {
        state,
      };
  }
};

export const ApplicationContextProvider = (props) => {
  const [state, defaultDispatch] = useReducer(reducer, initialState);

  // Thunk for leveraging async actions
  const dispatch = (action) => {
    if (typeof action === "function") {
      action(dispatch);
    } else {
      defaultDispatch(action);
    }
  };

  return (
    <ApplicationContext.Provider value={[state, dispatch]}>
      {props.children}
    </ApplicationContext.Provider>
  );
};
