import React, { useState, useContext, useEffect, useRef } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Geosuggest from "react-geosuggest";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { LoaderButton } from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { isEmail, regexAccountId } from "../../libs/utilities";
import { useFormFields } from "../../hooks";
import Main from "../../layouts/Main";
import "./Register.css";
import { ApplicationContext } from "../../context/GlobalState";
import {
	userHasAuthenticated,
	setUserEmail,
	updateUser,
	setUserBillingAddress,
	setUserPrimaryAccountId,
} from "../../context/actions";

import config from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

const checkCircle = <FontAwesomeIcon icon={faCheckCircle} />;
const checkX = <FontAwesomeIcon icon={faTimesCircle} />;

/* global google */

const Register = () => {
	const [state, dispatch] = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState("0");
	const [uniqueId, setUniqueId] = useState("");
	//const [newUser, setNewUser] = useState(null);

	const [fields, handleFieldChange] = useFormFields({
		email: "",
		userName: "",
		firstName: "",
		lastName: "",
		phoneNumber: "",
		corporateName: "",
		password: "",
		confirmPassword: "",
		confirmationCode: "",
		streetAddress: "",
		streetAddressLine2: "",
		city: "",
		zipCode: "",
		affiliateId: "",
		userRole: "",
		primaryAccountId: "",
	});

	const { user, barRef, billingAddress } = state;
	const nav = useNavigate();
	const geosuggestEl = useRef(null);
	console.log("REGISTER_STATE: ", state);

	/*
	 *	NOTE: Capture Affiliate ID here
	 *
	 */
	let [searchParams, setSearchParams] = useSearchParams();
	console.log("clues", searchParams.get("affiliateID") === null);

	let affiliateId = searchParams.get("affiliateID");
	affiliateId !== null
		? (affiliateId = searchParams.get("affiliateID"))
		: (affiliateId = "NONE"); // NOTE: ?affiliateId=1234567_ID&systemAdminRef=AutoMater2022

	let systemAdminRef = searchParams.get("systemAdminRef");
	systemAdminRef === "AutoMater2022"
		? (systemAdminRef = searchParams.get("systemAdminRef"))
		: (systemAdminRef = "NONE");

	console.log("queryParams: ", affiliateId + " " + systemAdminRef);

	useEffect(() => {
		if (isLoading) {
			barRef?.current?.continuousStart();
		} else {
			barRef?.current?.complete();
		}
	}, [isLoading]);

	useEffect(() => {
		console.log("RegisterState1: ", state);
		console.log("RegisterState2: ", fields.userRole);
	});

	const validateKycForm = () => {
		return (
			fields.corporateName.length > 0 &&
			fields.email.length > 0 &&
			fields.lastName.length > 0 &&
			fields.firstName.length > 0 &&
			isEmail(fields.email) &&
			billingAddress.streetAddress.length > 0 &&
			billingAddress.city.length > 0
		);
	};

	const validatePasswordForm = () => {
		return (
			fields.password.length >= 8 &&
			fields.password === fields.confirmPassword
		);
	};

	const validateConfirmationForm = () => {
		//
		return fields.confirmationCode.length > 0;
	};

	// FIXME!!!!! --> Dead!!!
	const handleBlur = async (event) => {
		//this.getLastNumber();
		console.log("LOOKING FOR ID TGT: ", event.target.id);

		if ((event.target.id = "userName")) {
			if (fields.userName === "") {
				setUniqueId("");
			}

			try {
				const res = await checkNewUserName();
				console.log("THISSSSS: ", res);

				console.log("AQUIIIII: ", res.error);
				if (res.status === true) {
					// username already exists!!!
					setUniqueId(false);
				} else {
					// username already exists!!!
					setUniqueId("");
				}
			} catch (err) {
				// NOTE: This happens on 500 need to confirm against responses!!!
				// username does not exist in db!!!
				if (fields.userName.length > 0) {
					setUniqueId(true);
				} else if (fields.userName === "") {
					setUniqueId("");
				}
				console.log("ERROR: ", err);
			}
		}
	};

	const checkNewUserName = async () => {
		console.log("checkNewUser called");
		return await API.get("nucleus-api", `/users/search/${fields.userName}`);
	};

	// Function that call backend user endpoint to post new user to db
	const createNewUser = (user) => {
		return API.post("nucleus-api", "/users/add", {
			body: user,
		});
	};

	// TODO: Function that call backend user endpoint to post new user to db
	const createNewCorp = (corp) => {
		return API.post("nucleus-api", "/corporate-record", {
			body: corp,
		});
	};

	const deleteUserInviteRecord = () => {
		return API.del(
			"chargeback-guardians-api",
			`/user-invite/${user.primaryAccountId}/${user.userName}`
		);
	};

	const handleSubmit = async (e) => {
		//
		e.preventDefault();
		setIsLoading(true);
		let user = {
			username: fields.email.toLowerCase(),
			password: fields.password,
			attributes: {
				"custom:userRole":
					systemAdminRef === "AutoMater2022"
						? "System_Admin"
						: "User_Admin",
				"custom:superId": state.primaryAccountId, //this.state.primaryAccountId.toLowerCase(), // custom attribute, not standard
				"custom:userName": fields.userName, //this.state.userName.toLowerCase(), // custom attribute, not standard
			},
		};

		try {
			// NOTE: handle login from AWS
			const authUser = await Auth.signUp(user);
			/**
			 *
			 * SystemAdmin
			 * SystemEmployee
			 *
			 * AgencyAdmin
			 * AgencyEmployee
			 *
			 * UserAdmin
			 * UserEmployee
			 *
			 */

			console.log("Sent Confirmation For: ", authUser);

			dispatch(setUserPrimaryAccountId(state.primaryAccountId));

			dispatch(
				updateUser({
					userRole: user.attributes["custom:userRole"],
					superId: state.primaryAccountId,
					username: user.attributes["custom:userName"],
					verified: authUser.user.userConfirmed,
					password: fields.password,
				})
			); // NOTE: make sure to update space with authUser attributes alone

			//setNewUser(authUser);
			setIsLoading(false);

			// register newUser -----> new
		} catch (e) {
			//

			try {
				console.log(
					"handleSubmit user registration error: ",
					e.message
				);
				if (
					e.message ===
					"An account with the given email already exists."
				) {
					// need to set state to not-null to bring up confirmation screen again
					console.log("part-2 register error");

					const authUser = await Auth.resendSignUp(
						fields.email.toLowerCase()
					);

					console.log("Resent Confirmation For: ", authUser);

					onError(
						e.message +
							" Please check your email for a new confirmation code!"
					);

					dispatch(updateUser(user));
					dispatch(setUserEmail(fields.email));
					setIsLoading(false);
				} else {
					onError(e.message);
					setIsLoading(false);
					nav("/login");
				}
			} catch (err) {
				onError(err.message);
				setIsLoading(false);
				nav("/login");
			}
		}
	};

	const handleConfirmationSubmit = async (e) => {
		//
		e.preventDefault();
		setIsLoading(true);

		try {
			//
			await Auth.confirmSignUp(
				state.email.toLowerCase(),
				fields.confirmationCode
			);
			await Auth.signIn(state.email.toLowerCase(), state.user.password);

			dispatch(userHasAuthenticated(true));

			try {
				// create new user in db AFTER user confirms registration email ONLY
				await createNewUser({
					primaryAccountId: state.primaryAccountId.toLowerCase(),
					userName: fields.userName.toLowerCase(),
					userRole:
						systemAdminRef === "AutoMater2022"
							? "System_Admin"
							: "User_Admin",
					accountStatus: "REGISTERED",
					corporateName: fields.corporateName,
					streetAddress: billingAddress.streetAddress,
					addressLine2: fields.streetAddressLine2
						? fields.streetAddressLine2
						: "",
					city: state.billingAddress.city,
					stateOf: state.billingAddress.state,
					zipCode: state.billingAddress.zipCode,
					country: state.billingAddress.country,
					firstName: fields.firstName,
					lastName: fields.lastName,
					email: fields.email,
					affiliateId: affiliateId || null,
				});

				if (state.user.userRole !== "User_Admin") {
					// This deletes the temp user invitation from the unsecure contact db
					await deleteUserInviteRecord();
				} else if (state.user.userRole === "User_Admin") {
					try {
						await createNewCorp({
							primaryAccountId:
								state.primaryAccountId.toLowerCase(),
							corporateName: fields.corporateName,
							streetAddress: billingAddress.streetAddress,
							addressLine2: fields.addressLine2
								? fields.addressLine2
								: "",
							city: state.billingAddress.city,
							stateOf: state.billingAddress.state,
							zipCode: state.billingAddress.zipCode,
							country: state.billingAddress.country,
							corpContact:
								fields.firstName + " " + fields.lastName,
							corpEmail: fields.email,
						});

						// this is after corp create
					} catch (err) {
						// this is catching second try statement

						console.log("DEAD END: ", err);
					}
				}
			} catch (e) {
				//
				console.log("CreateNewUser Error: ", e);
			}

			// Set isLoading to false
			setIsLoading(false);
			nav("/");
		} catch (e) {
			//
			console.log("ConfirmSubmit Error: ", e);
			onError(e);
			setIsLoading(false);
			nav("/login");
		}
	};

	/**
	 *  @function regexAddress
	 *
	 *  This functions splits the selected suggestion
	 *  into parts to return the street address line
	 *  1 data.
	 *
	 *  @param suggestion
	 *
	 *  @return [streetAddress, city, state]
	 *
	 */
	const regexAddress = (suggestion) => {
		if (suggestion.length > 0) {
			// FIXME: When address is 1085-1087 Boylston St Regex fails at 1085-
			let streetAddress = suggestion.split(", ")[0];
			let city = suggestion.split(", ")[1];
			let state = suggestion.split(", ")[2];
			console.log("REGEX 1: ", [streetAddress, city, state]);
			return [streetAddress, city, state];
		}
	};

	/**
	 *
	 * @function zipCodeSearch
	 *
	 */
	const zipCodeSearch = async (geoCodeURL) => {
		let formattedAddress;
		let url = geoCodeURL;

		// NOTE: FIXED THE ERROR WITH PARSING THE JSON RESPONSE
		// Need to get address data back from google
		// to pull zipcode data
		// eslint-disable-next-line
		await fetch(url)
			.then((response) => response.json())
			// eslint-disable-next-line
			.then(
				(data) =>
					(formattedAddress =
						data.results[0].formatted_address.split(", "))
			);

		console.log("FORMATTED ADDRESS1", formattedAddress);

		let city = formattedAddress[formattedAddress.length - 3];
		let stateOf =
			formattedAddress[formattedAddress.length - 2].split(" ")[0];
		let zipCode =
			formattedAddress[formattedAddress.length - 2].split(" ")[1];
		let country = formattedAddress[formattedAddress.length - 1];

		let primaryAccountId = (regexAccountId(fields.corporateName) + zipCode)
			.replace(/ /g, "")
			.toLowerCase();

		dispatch(setUserPrimaryAccountId(primaryAccountId));

		return {
			primaryAccountId: primaryAccountId,
			city: city,
			stateOf: stateOf,
			zipCode: zipCode,
			country: country,
		};

		// 711 East Okeechobee Road, Hialeah, FL 33010, USA
	};

	/**
	 *
	 * @function selectSuggestion
	 *
	 */
	const useSelectBillToSuggestion = async (suggestion) => {
		try {
			let streetAddress = suggestion.label;

			let apiKey = config.Places_API_KEY;
			let reg = regexAddress(streetAddress);

			streetAddress = reg[0];

			let url =
				"https://maps.googleapis.com/maps/api/geocode/json?address=";

			let address = streetAddress.split(" ");

			let query = "";

			for (let i = 0; i < address.length; i++) {
				if (i === address.length - 1) {
					query = query + address[i];
				} else {
					query = query + address[i] + "+";
				}
			}

			// build the google api url we need to get location data
			url += query + "+" + reg[1] + "+" + reg[2] + "&key=" + apiKey;

			// NOTE: call the zipCodeSearch function so that
			//       we can extract location data from google
			//       response inside of formatted_address field
			let { primaryAccountId, city, stateOf, zipCode, country } =
				await zipCodeSearch(url);

			let billToAddress = {
				streetAddress: streetAddress,
				streetAddressLine2: "",
				city: city,
				stateOf: stateOf,
				zipCode: zipCode,
				country: country,
			};

			dispatch(setUserBillingAddress(billToAddress));

			//dispatch(setUserBillingAddress(billToAddress));
		} catch (err) {
			console.log("SELECT SUGGESTION ADDRESS CLEARED: ", err);
		}
	};

	const renderConfirmationForm = () => {
		//
		return (
			//
			<Form
				onSubmit={handleConfirmationSubmit}
				className="registration-form"
			>
				<Form.Group controlId="confirmationCode" size="lg">
					<Form.Label>Confirmation Code</Form.Label>
					<Form.Control
						autoFocus
						type="tel"
						onChange={handleFieldChange}
						value={fields.confirmationCode}
						className="nucleus-form-fields"
					/>
					<Form.Text muted>
						Please check your email and your SPAM folder for the
						code or verification link sent from an{" "}
						<code>adNucleus.com</code> email address.
					</Form.Text>
				</Form.Group>

				{affiliateId !== "NONE" && (
					<Form.Group controlId="affiliateId" size="lg" id="fg3">
						<Form.Label>Affiliate Code</Form.Label>
						<Form.Control
							type="test"
							value={affiliateId}
							onChange={handleFieldChange}
							className="nucleus-form-fields-read-only"
							readOnly
						/>
						<Form.Text muted>Just a test.</Form.Text>
					</Form.Group>
				)}

				<section className="registration-submit-btn">
					<LoaderButton
						block="true"
						size="lg"
						type="submit"
						variant="success"
						isLoading={isLoading}
						disabled={!validateConfirmationForm()}
					>
						Verify
					</LoaderButton>
				</section>
			</Form>
		);
	};

	const renderKycForm = () => {
		//
		return (
			<Form onSubmit={handleSubmit} className="registration-form">
				<Form.Group controlId="email" size="lg">
					<Form.Label>Corporate Email</Form.Label>
					<Form.Control
						autoFocus
						type="email"
						value={fields.email}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						required={true}
					/>
				</Form.Group>

				<Container fluid id="fg4c" className="no-side-padding-register">
					<Row className="contain-row-register">
						<Col className="right-side-padding-register">
							<Form.Group controlId="firstName" size="lg">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									type="text"
									value={fields.firstName}
									onChange={handleFieldChange}
									className="nucleus-form-fields"
									required={true}
								/>
							</Form.Group>
						</Col>
						<Col className="left-side-padding-register">
							<Form.Group controlId="lastName" size="lg">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									type="text"
									value={fields.lastName}
									onChange={handleFieldChange}
									className="nucleus-form-fields"
									required={true}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Container>

				<Form.Group controlId="corporateName" size="lg" id="fg4a">
					<Form.Label>Company Name</Form.Label>
					<Form.Control
						type="text"
						value={fields.corporateName}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						placeholder="ACME Industries, LLC"
						required={true}
					/>
				</Form.Group>

				<Form.Group controlId="streetAddress" id="fg4a">
					<Form.Label>Street Address</Form.Label>
					<Geosuggest
						ref={geosuggestEl}
						initialValue={
							isNaN(billingAddress.streetAddress) ||
							billingAddress.streetAddress.length > 0
								? billingAddress.streetAddress
								: fields.streetAddress
						}
						placeholder="Enter your Street Address!"
						country="us"
						onSuggestSelect={useSelectBillToSuggestion}
						location={
							new google.maps.LatLng(
								36.0952973472837,
								-79.43409278184876
							)
						}
						radius="5000000"
						readOnly={
							fields.corporateName.length > 0 ? false : true
						}
						required={true}
					/>
				</Form.Group>

				<Form.Group controlId="streetAddressLine2" size="lg" id="fg4a">
					<Form.Label>Suite or Apartment</Form.Label>
					<Form.Control
						autoFocus
						type="streetAddressLine2"
						value={
							isNaN(billingAddress.streetAddressLine2) ||
							billingAddress.streetAddressLine2.length > 0
								? billingAddress.streetAddressLine2
								: fields.streetAddressLine2
						}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						readOnly={
							fields.corporateName.length > 0 ? false : true
						}
					/>
				</Form.Group>

				<Container fluid id="fg4b" className="no-side-padding-register">
					<Row className="contain-row-register">
						<Col className="right-side-padding-register">
							<Form.Group controlId="city" size="lg">
								<Form.Label>City</Form.Label>
								<Form.Control
									type="text"
									value={
										isNaN(billingAddress.city) ||
										billingAddress.city.length > 0
											? billingAddress.city
											: fields.city
									}
									onChange={handleFieldChange}
									className="nucleus-form-fields-read-only"
									readOnly
									placeholder="auto"
								/>
							</Form.Group>
						</Col>
						<Col className="left-side-padding-register">
							<Form.Group controlId="zipCode" size="lg">
								<Form.Label>Zip Code</Form.Label>
								<Form.Control
									type="text"
									value={
										isNaN(billingAddress.zipCode) ||
										billingAddress.zipCode.length > 0
											? billingAddress.zipCode
											: fields.zipCode
									}
									onChange={handleFieldChange}
									className="nucleus-form-fields-read-only"
									readOnly
									placeholder="fill"
								/>
							</Form.Group>
						</Col>
					</Row>
				</Container>

				<section className="registration-submit-btn">
					<LoaderButton
						block="true"
						size="lg"
						type="button"
						variant="success"
						onClick={() => {
							dispatch(setUserEmail(fields.email.toLowerCase()));
							if (fields.streetAddressLine2.length > 0) {
								dispatch(
									setUserBillingAddress({
										streetAddress:
											billingAddress.streetAddress,
										streetAddressLine2:
											fields.streetAddressLine2,
										city: billingAddress.city,
										stateOf: billingAddress.stateOf,
										zipCode: billingAddress.zipCode,
										country: billingAddress.country,
									})
								);
							}
							setStep("1");
						}}
						isLoading={isLoading}
						disabled={!validateKycForm()}
					>
						Next
					</LoaderButton>
				</section>
			</Form>
		);
	};

	const renderPasswordForm = () => {
		let uniqueClass = "";
		let notUniqueClass = "";

		if (uniqueId === "") {
			//
			console.log("The ID IS Blank!!!");
			uniqueClass = "neutral";
			notUniqueClass = "neutral";
		} else if (uniqueId) {
			//
			console.log("The ID IS UNIQUE!!!");
			uniqueClass = "please-display";
			notUniqueClass = "do-not-display";
		} else {
			console.log("The ID IS NOT UNIQUE!!!");
			notUniqueClass = "please-display";
			uniqueClass = "do-not-display";
		}
		//
		return (
			<Form onSubmit={handleSubmit} className="registration-form">
				<Form.Group controlId="userRole" size="lg">
					<Form.Label>User Role</Form.Label>
					<Form.Control
						type="test"
						value={
							systemAdminRef === "AutoMater2022"
								? "System_Admin"
								: "User_Admin"
						}
						onChange={handleFieldChange}
						className="nucleus-form-fields-read-only"
						readOnly
					/>
					<Form.Text muted style={{ fontSize: "12px" }}>
						Your permissions on platform are set by your role.
					</Form.Text>
				</Form.Group>

				{/* TODO: Need to validate against userName in db for uniqueness. */}
				<Form.Group
					controlId="userName"
					size="lg"
					id="fg4d"
					onBlur={handleBlur}
				>
					<Form.Label>Username</Form.Label>
					<Form.Control
						type="text"
						onChange={handleFieldChange}
						value={fields.userName}
						className="nucleus-form-fields"
						placeholder="Create a unique username"
						required={true}
					/>
					<div
						className={uniqueClass}
						style={{
							color: "green",
							transform: "translateY(3%)",
						}}
					>
						{checkCircle} {" This username is available!!!"}
					</div>
					<div
						className={notUniqueClass}
						style={{
							color: "red",
							transform: "translateY(3%)",
						}}
					>
						{checkX} {" This username is already taken!!!"}
					</div>
				</Form.Group>

				<Form.Group controlId="password" size="lg" id="fg3">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						autoComplete="new-password"
					/>
					<Form.Text muted style={{ fontSize: "12px" }}>
						Must use eight characters, include at least one number
						and use both lower and uppercase letters, and special
						characters, for example <code>#, ?, !</code>.
					</Form.Text>
				</Form.Group>

				<Form.Group controlId="confirmPassword" size="lg" id="fg4">
					<Form.Label>Confirm Password</Form.Label>
					<Form.Control
						type="password"
						value={fields.confirmPassword}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						autoComplete="new-password"
					/>
				</Form.Group>

				<section className="registration-submit-btn">
					<LoaderButton
						block="true"
						size="lg"
						type="submit"
						variant="success"
						isLoading={isLoading}
						disabled={!validatePasswordForm()}
					>
						Register
					</LoaderButton>
				</section>
			</Form>
		);
	};

	return (
		<>
			<Main>
				<article className="Registration">
					{user === null
						? step === "0"
							? renderKycForm()
							: renderPasswordForm() //renderPasswordForm()
						: renderConfirmationForm()}
				</article>
			</Main>
		</>
	);
};

export default Register;
